<template>
  <b-card>
    {{description}}
    <b-form-file
      v-model="images"
      :state="Boolean(images)"
      placeholder="Choose an image or drop it here..."
      drop-placeholder="Drop image here..."
      multiple
    ></b-form-file>
    <div class="mt-3">Selected image: {{ images ? images.name : '' }}</div>
    <b-progress :value="in_progress" :max="max_progress" show-progress animated></b-progress>
    <b-button block variant="primary" @click="storeImages">Update Image</b-button>
  </b-card>
</template>

<script>
import {http_v1} from '@/axios'
import {belongsToDescription} from '@/utils'

export default {
  props: {
    id: {
      Type: String
    },
    collection_id: {
      Type: String
    }
  },
  data () {
    return {
      ams_type: 'collection-images',
      ams_api_path: 'api/admin/v1/',
      attr_copy: {},
      attr_orig: {},
      description: undefined,
      describe_belongs_to: [
        {
          prepend: '',
          type: 'collections',
          description_attr: 'name',
          append: ' '
        }
      ],
      images: [],
      in_progress: 0,
      max_progress: 100,
      progressInfos: [],
      message: "",
      fileInfos: []
    }
  },
  methods: {
    uploadApi(file, onUploadProgress) {
      let formData = new FormData()
      formData.append("image", file)
      // formData.append("id", this.id)
      return http_v1.patch(this.apiPath(), formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress
      })
    },
    uploadImage(idx, file) {
      this.progressInfos[idx] = { percentage: 0, fileName: file.name }
      this.uploadApi(file, (event) => {
        this.progressInfos[idx].percentage = Math.round(100 * event.loaded / event.total)
        this.in_progress = this.progressInfos[idx].percentage
      }).then((response) => {
        let prevMessage = this.message ? this.message + "\n" : ""
        this.message = prevMessage + response.data.message
      }).catch(() => {
        this.progressInfos[idx].percentage = 0
        this.message = "Could not upload the file:" + file.name
      })
    },
    storeImages () {
      this.message = ""
      for(let i = 0; i< this.images.length; i++ ) {
        this.uploadImage(i, this.images[i])
      }
      this.images = []
    },
    apiPath() {
      return this.ams_api_path + this.ams_type.replace(/-/g, '_') + '/' + this.id
    },
  },
  mounted () {
    http_v1.get(this.apiPath(), {
    }).then(response => {
      if( response.data) {
        this.attr_copy = Object.assign( {}, response.data.data.attributes)
        this.attr_orig = Object.assign( {}, response.data.data.attributes)
        this.description = belongsToDescription(
          response.data.data, response.data.included, this.describe_belongs_to
        )
        this.$emit('gotAttrs', this.attr_copy, this.description)
      }
    }).catch(error => {
      if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
  }
}
</script>