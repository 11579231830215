<template>
  <div>
    <b-card bg-variant="none">
      <find-or-create-brand
        v-model="brand" @product_obj="updateBrand"
      ></find-or-create-brand>
      <find-or-create-product-model
        v-model="product_model" @product_obj="updateModel"
        :brand_ids="brand_ids"
      ></find-or-create-product-model>
      <div v-if="showProductListing">
      <find-or-create-product-listing
        v-model="product_listing" @product_obj="updateListing"
        :product_model_ids="product_model_ids"
      ></find-or-create-product-listing>
      </div>
      <div v-if="showProductItem">
      <find-or-create-product-item
        :collection_id="collection_id"
        v-model="product_item" @product_obj="updateItem"
        :product_listing_ids="product_listing_ids"
      ></find-or-create-product-item>
      </div>
      <div size="sm" class="mt-3">Using: <strong>{{ usageDescription }}</strong></div>
    </b-card>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import FindOrCreateBrand from './FindOrCreateBrand'
import FindOrCreateProductModel from './FindOrCreateProductModel'
import FindOrCreateProductListing from './FindOrCreateProductListing'
import FindOrCreateProductItem from './FindOrCreateProductItem'

export default {
  components: {
    FindOrCreateBrand,
    FindOrCreateProductModel,
    FindOrCreateProductListing,
    FindOrCreateProductItem,
  },
  props: {
    collection_id: {
      Type: String
    }
  },
  data () {
    return {
      brand: {attributes: {name: ''}},
      product_model: {attributes: {name: ''}},
      product_listing: {attributes: {edition: 0}},
      product_item: {attributes: {'serial-number': 0}},
      brand_ids: [],
      product_model_ids: [],
      product_listing_ids: [],
      product_item_ids: [],
    }
  },
  computed: {
    ...mapGetters({
      cached_brands: 'cached_products/brands',
    }),
    showProductListing() {
      return this.product_model?.id
    },
    showProductItem() {
       return this.product_listing?.id
    },
    usageDescription() {
      return [
        this.brand.attributes['name'],
        this.product_model.attributes['name'],
        this.product_listing.attributes['edition'],
        this.product_item.attributes['serial-number']
      ].filter(Boolean).join(":")
      // filter(Boolean) to remove any falsies, undefined, nulls, etc.
    },
  },
  methods: {
    updateBrand (val) {
      this.brand = val
      this.product_model = {attributes: {name: ''}}
      this.product_listing = {attributes: {edition: 0}}
      this.product_item = {attributes: {'serial-number': 0}}
    },
    updateModel(val) {
      this.brand = this.cached_brands.find( 
        el =>  
        el.attributes['id'] === val.attributes['brand-id']
      )
      this.product_model = val
      this.product_listing = {attributes: {edition: 0}}
      this.product_item = {attributes: {'serial-number': 0}}
    },
    updateListing(val) {
      this.product_listing = val
      this.product_item = {attributes: {'serial-number': 0}}
    },
    updateItem(val) {
      this.product_item = val
    },
  },
  watch: {
    brand(newVal) {
      this.brand_ids = [newVal.id]
    },
    product_model(newVal) {
      this.product_model_ids = [newVal.id]    
    },
    product_listing(newVal) {
      this.product_listing_ids = [newVal.id]
    },
    product_item(newVal) {
      this.product_item_ids = [newVal.id]
      this.$emit('usingProduct', newVal)
    }
  }
}
</script>
