<template>
  <div class="text-success"> {{computedValue}}
    <b-icon-check2 v-if="computedAuthenticated" aria-hidden="true"></b-icon-check2>
  </div>
</template>

<script>
/*
 collection_id: uuid,
 product_listing_id: uuid,
 description: text
 serial_number: integer, 
 purchased_price_cents: integer, 
 estimated_min_price_cents: integer, 
 estimated_max_price_cents: integer, 
 authenticated_min_price_cents: integer, 
 authenticated_max_price_cents: integer, 
 asking_price_cents: integer, 
 sold_price_cents: integer, 
 estimated_quality: integer, 
 authenticated_quality: integer, 
 at_status: integer, 
 collected_at: datetime, 
 prepared_at: datetime, 
 delivered_at: datetime, 
 received_at: datetime
*/
import { BIconCheck2 } from 'bootstrap-vue'

export default {
  components: {
    BIconCheck2
  },
  props: {
    product_item: {
      Type: Object
    },
  },
  data() { 
    return {
      string_val: '$$$$$$$$$$'
    }
  },
  computed: {
    computedVariant () {
      return this.product_item['authenticated-min-price-cents'] || this.product_item['authenticated-max-prices-cents'] ? "text-success" : "text-primary"
    },
    computedAuthenticated () {
      return this.product_item['authenticated-min-price-cents'] || this.product_item['authenticated-max-prices-cents'] ? true : false
    },
    computedValue () {
      let min_val = this.product_item['authenticated-min-price-cents'] || this.product_item['estimated-min-price-cents'] || this.product_item['purchased-price-cents'] || 0
      let max_val = this.product_item['authenticated-max-price-cents'] || this.product_item['estimated-max-price-cents'] || 0
      let valued_at = max_val || min_val
      let valued_at_rating = String(valued_at).length - 1
      return this.string_val.substring(1,valued_at_rating)
    }
  }
}
</script>