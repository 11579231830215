<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <b-card no-body class="overflow-hidden" style="max-width: 1080px;">
      <b-row>
        <b-col md="6">
          <b-card-img :src="attr_copy['image-url']" alt="Image" class="rounded-0"></b-card-img>
            <b-card-body>
              <collection-image-update :id="id"> </collection-image-update>
             <!-- <b-button block variant="primary" @click="replaceImage">Replace Image</b-button> -->
          </b-card-body>
        </b-col>
        <b-col md="6">
          <find-or-create-product
            :collection_image_id="id"
            :collection_id="attr_copy['collection-id']"
            @usingProduct="currentProduct"
          ></find-or-create-product>
          <b-button block variant="primary" @click="linkProduct" :disabled="disableLink">Link Product to Image</b-button>
          <item-list-card
            :data_item="item"
            :mappings="association_items"
            :included="association_included"
          ></item-list-card>
        </b-col>
      </b-row>
      <b-button block variant="primary" @click="callDone">Done</b-button>
    </b-card>
  </div>
</template>

<script>
import {http_v1} from '@/axios'
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import {belongsToDescription} from '@/utils'
import FindOrCreateProduct from './FindOrCreateProduct'
import ItemListCard from './ItemListCard'
import CollectionImageUpdate from './CollectionImageUpdate'

export default {
  components: {
    VuexBreadcrumb,
    FindOrCreateProduct,
    ItemListCard,
    CollectionImageUpdate,
  },
  props: {
    id: {
      Type: String
    }
  },
  data () {
    return {
      // value: 3,
      attr_copy: {},
      attr_orig: {},
      item: {},
      included: [],
      association_items: [],
      association_included: [],
      totalRows: 0,
      perPage: 0,
      selected_product: {},
      describe_title: [
        {
          prepend: '',
          type: 'collections',
          description_attr: 'name',
          append: ''
        }
      ],
      describe_subtitle: [
        {
          prepend: '',
          type: 'collections',
          description_attr: 'access-permission',
          append: ''
        }
      ]
    }
  },
  computed: {
    collectionName() {
      return belongsToDescription(this.item, this.included, this.describe_title)
    },
    subTitle() {
      return belongsToDescription(this.item, this.included, this.describe_subtitle)
    },
    disableLink() {
      return !(this.selected_product?.id && this.id)
    }
  },
  methods: {
    getItems () {
      http_v1.get (
        'api/admin/v1/item_image_associations', {
          params: {
            collection_image_ids: this.id,
          }
        }
      ).then(response => {
        if(response.status == 200) {
          this.totalRows = response.headers.total
          this.perPage = response.headers['per-page']
          this.association_items = response.data.data
          this.association_included = response.data.included
        }
        else {
          window.console.log(response.status)
        }
      }).catch(error => {
        this.association_items = []
        this.totalRows = 0
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          window.console.log(message)
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
    currentProduct(val) {
      this.selected_product = val
    },
    linkProduct() {
      http_v1.post (
        'api/admin/v1/item_image_associations', {
          product_item_id: this.selected_product.id,
          collection_image_id: this.id
        }
      ).then(response => {
        var message = response.data.message || 'Item Image associated'
        this.$bvToast.toast(message, {
          title: 'Success',
          autoHideDelay: 5000,
          appendToast: true
        })
        this.getItems()
        // this.$emit('takeAction')
        // if(this.reroute) {
        //   this.$router.push( {name: 'ProductItemIndex'})
        // }
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
    callApi () {
      http_v1.get('api/admin/v1/collection_images_with_product_items/' + this.id, {
      }).then(response => {
        if( response.data) {
          this.attr_copy = Object.assign( {}, response.data.data.attributes)
          this.attr_orig = Object.assign( {}, response.data.data.attributes)
          this.item = Object.assign( {}, response.data.data )
          this.included = response.data.included
          this.getItems()
        }
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
    callDone() {
      this.$router.go(-1)
    },
  },
  mounted () {
    this.callApi()
  }
}
</script>

 