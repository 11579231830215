<template>
  <div v-if="find_product">
    <b-input-group>
      <b-form-input size="sm" class="mt-3"
        placeholder="Serial / Copy #"
        id="nested-purchased-price" type="number"
        v-model="product"
      >
      </b-form-input>
      <b-input-group-append>
        <b-button variant="outline-success" size="sm" class="mt-3" v-show="showApprove" @click="callApprove">
          <b-icon-check2 aria-hidden="true"></b-icon-check2>
        </b-button>
        <b-button variant="outline-primary" size="sm" class="mt-3" v-show="showSearch" @click="callSearch">
          <b-icon-search aria-hidden="true"></b-icon-search>
        </b-button>
        <b-button variant="outline-primary" size="sm" class="mt-3" v-show="showAdd" @click="callAdd">
          <b-icon-plus-circle aria-hidden="true"></b-icon-plus-circle>
        </b-button>
        <b-button variant="outline-secondary" size="sm" class="mt-3" v-show="showClear" @click="callClear">
          <b-icon-x-circle aria-hidden="true"></b-icon-x-circle>
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
  <div v-else>
    <product-item-create :ams_template="orig_template" :reroute="false" @takeAction="takeAction" @noAction="noAction"></product-item-create>
  </div>
</template>

<script>
import {http_v1} from '@/axios'
import { BIconXCircle, BIconSearch, BIconPlusCircle, BIconCheck2 } from 'bootstrap-vue'
import {mapGetters} from 'vuex'
import ProductItemCreate from './ProductItemCreate'
import {amsSchema} from '@/schema'

export default {
  components: {
    BIconXCircle,
    BIconSearch,
    BIconPlusCircle,
    BIconCheck2,
    ProductItemCreate
  },
  props: {
    product_listing_ids: {
      Type: Array
    },
    collection_id: {
      Type: String
    },
  },
  data () {
    return {
      product: undefined,
      isUsable: false,
      usingProductObj: undefined,
      products: [],
      find_product: true,
      orig_template: {}
    }
  },
  computed: {
    ...mapGetters({
      cached_products: 'cached_products/product_items',
    }),
    showApprove() {
      return (this.product) && (this.isUsable === true)
    },
    showSearch() {
      return (this.product) && (this.isUsable === false)
    },
    showAdd() {
      return (this.product) && (this.isUsable === false)
    },
    showClear() {
      return this.product?.length
    },
  },
  methods: {
    callApprove() {
      this.usingProductObj = this.cached_products.find( 
        el =>  
        el.attributes['serial-number'] === parseInt(this.product) && el.attributes['product-listing-id'] === this.product_listing_ids[0]
      )
    },
    callSearch() {
      http_v1.get (
        'api/admin/v1/product_items', {
          params: {
            serial_numbers: this.product,
            product_listing_ids: this.product_listing_ids,
            collection_ids: this.collection_id
          }
        }
      ).then(response => {
        if( response.data) {
          this.$store.dispatch('cached_products/add_products', response.data.data).then(
            () => {
              this.$store.dispatch('cached_products/add_products', response.data.included).then( 
                () => {
                window.console.log('dispatch2')
              })
            }
          )
          var message = `Found ${response.data.data?.length} titles`
          this.$bvToast.toast(message, {
            title: 'Success',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
    callAdd() {
      this.find_product = false
    },
    takeAction() {
      this.find_product = true
      this.isUsable = true
    },
    noAction() {
      this.find_product = true
    },
    callClear() {
      this.product = 0
      this.isUsable = false
      this.usingProductObj = undefined
      this.$emit('product_obj', {attributes: {'serial-number': 0}})
    },
    setProducts() {
      this.products = this.cached_products.map(f => {
        return { text: f.attributes['serial-number'], value: f.id}
      })
    }
  },
  watch: {
    cached_products() {
      this.setProducts()
    },
    product(newVal) {
      if(newVal) {
        let included_obj = this.cached_products?.find( 
          el =>  
          el.attributes['serial-number'] === parseInt(newVal) && el.attributes['product-listing-id'] === this.product_listing_ids[0]
        )
        this.isUsable = included_obj?.id ? true : false
        this.orig_template.attributes['serial-number'] = newVal
        this.orig_template.attributes['product-listing-id'] = this.product_listing_ids[0]
        this.orig_template.attributes['collection-id'] = this.collection_id
      }
    },
    usingProductObj(newVal) {
      if(newVal) {
        this.$emit('product_obj', newVal)
      }
    }
  },
  mounted() {
    this.setProducts()
    let included_obj = amsSchema.find( 
      el =>  
      el.type === 'product-items'
    )
    this.orig_template = included_obj
  }
}
</script>