<template>
  <b-form-rating v-model="computedValue" readonly variant="warning" class="mb-2" size="sm" inline></b-form-rating>
</template>

<script>
export default {
  props: {
    value: {
      Type: Number
    },
  },
  computed: {
    computedValue: {
      get: function() {
        return this.value.toFixed(2) / 2000
      },
      set: function(modifiedValue) {
        this.$emit('input', modifiedValue)
      }
    }
  }
}
</script>