<template>
  <div v-if="find_product">
    <b-input-group>
      <b-form-input list="model-list-id" size="sm" class="mt-3" v-model="product" placeholder="Search for Title / Model Name..."></b-form-input>
      <datalist id="model-list-id">
        <option v-for="product in products" :key="product.value">{{ product.text }}</option>
      </datalist>
      <b-input-group-append>
        <b-button variant="outline-success" size="sm" class="mt-3" v-show="showApprove" @click="callApprove">
          <b-icon-check2 aria-hidden="true"></b-icon-check2>
        </b-button>
        <b-button variant="outline-primary" size="sm" class="mt-3" v-show="showSearch" @click="callSearch">
          <b-icon-search aria-hidden="true"></b-icon-search>
        </b-button>
        <b-button variant="outline-primary" size="sm" class="mt-3" v-show="showAdd" @click="callAdd">
          <b-icon-plus-circle aria-hidden="true"></b-icon-plus-circle>
        </b-button>
        <b-button variant="outline-secondary" size="sm" class="mt-3" v-show="showClear" @click="callClear">
          <b-icon-x-circle aria-hidden="true"></b-icon-x-circle>
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
  <div v-else>
    <product-model-create :ams_template="orig_template" :reroute="false" @takeAction="takeAction" @noAction="noAction"></product-model-create>
  </div>
</template>

<script>
import {http_v1} from '@/axios'
import { BIconXCircle, BIconSearch, BIconPlusCircle, BIconCheck2 } from 'bootstrap-vue'
import {mapGetters} from 'vuex'
import ProductModelCreate from './ProductModelCreate'
import {amsSchema} from '@/schema'

export default {
  components: {
    BIconXCircle,
    BIconSearch,
    BIconPlusCircle,
    BIconCheck2,
    ProductModelCreate
  },
  props: {
     brand_ids: {
       Type: Array
     }
  },
  data () {
    return {
      product: '',
      isUsable: false,
      usingProductObj: undefined,
      products: [],
      find_product: true,
      orig_template: {}
    }
  },
  computed: {
    ...mapGetters({
      cached_products: 'cached_products/product_models',
    }),
    showApprove() {
      return (this.product.length) && (this.isUsable === true)
    },
    showSearch() {
      return (this.product.length) && (this.isUsable === false)
    },
    showAdd() {
      return (this.product.length) && (this.isUsable === false)
    },
    showClear() {
      return this.product.length
    },
  },
  methods: {
    callApprove() {
      this.usingProductObj = this.cached_products.find( 
        el =>  
        el.attributes['name'] === this.product && el.attributes['brand-id'] === this.brand_ids[0]
      )
    },
    callSearch() {
      http_v1.get (
        'api/admin/v1/product_models', {
          params: {
            name: this.product,
            brand_ids: this.brand_ids
          }
        }
      ).then(response => {
        if( response.data) {
          this.$store.dispatch('cached_products/add_products', response.data.data).then(
            () => {
              this.$store.dispatch('cached_products/add_products', response.data.included).then( 
                () => {
                window.console.log('dispatch2')
              })
            }
          )
          var message = `Found ${response.data.data?.length} titles`
          this.$bvToast.toast(message, {
            title: 'Success',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
    callAdd() {
      this.find_product = false
    },
    takeAction() {
      this.find_product = true
      this.isUsable = true
    },
    noAction() {
      this.find_product = true
    },
    callClear() {
      this.product = ''
      this.isUsable = false
      this.usingProductObj = undefined
      this.$emit('product_obj', {attributes: {name: ''}})
    },
    setProducts() {
      this.products = this.cached_products.map(f => {
        return { text: f.attributes['name'], value: f.id }
      })
    }
  },
  watch: {
    cached_products() {
      this.setProducts()
    },
    product(newVal) {
      if(newVal) {
        let included_obj = this.cached_products?.find( 
          el =>  
          el.attributes['name'] === newVal && el.attributes['brand-id'] === this.brand_ids[0]
        )
        this.isUsable = included_obj?.id ? true : false
        this.orig_template.attributes['name'] = newVal
        this.orig_template.attributes['brand-id'] = this.brand_ids[0]
        if(newVal.length == 5 && this.isUsable == false) {
          this.callSearch()
        }
      }
    },
    usingProductObj(newVal) {
      if(newVal) {
        this.$emit('product_obj', newVal)
      }
    }
  },
  mounted() {
    this.setProducts()
    let included_obj = amsSchema.find( 
      el =>  
      el.type === 'product-models'
    )
    this.orig_template = included_obj
  }
}
</script>