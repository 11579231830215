<template>
 <div>
  <b-row>
    <b-col md="1">
    </b-col>
    <b-col md="10">
      <b-card md="1" bg-variant="none"> {{description}}</b-card>
    </b-col>
    <b-col md="1">
    </b-col>
  </b-row>
  <br>
  <b-card bg-variant="light">
    <b-form-group 
      label-cols-lg="3"
      label="Brand"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
    >
      <b-form-group
        label="Name:"
        label-for="nested-name"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-name"
          v-model="attr_copy.name"
        ></b-form-input>
      </b-form-group>
    </b-form-group>
    <br>
    <b-row>
      <b-col>
        <b-button block variant="primary" :disabled="disableAdd" @click="callApi">Add</b-button>
      </b-col>
      <b-col>
        <b-button block variant="primary" @click="callCancel">Cancel</b-button>
      </b-col>
    </b-row>
  </b-card>
 </div>
</template>

<script>
import {http_v1} from '@/axios'
import {amsMofifiedAttrs} from '@/utils'
import {amsSchema} from '@/schema'

export default {
  props: {
    reroute: {
      Type: Boolean,
      default: true
    },
    ams_template: {
      Type: Object
    }
  },
  data () {
    return {
      ams_type: 'brands',
      ams_api_path: 'api/admin/v1/',
      attr_copy: {},
      attr_orig: {},
      description: undefined
    }
  },
  computed: {
    disableAdd() {
      return !(this.attr_copy['name']?.length)

    }
  },
  methods: {
    apiPath() {
      return this.ams_api_path + this.ams_type.replace(/-/g, '_')
    },
    callApi () {
      let attr_params = amsMofifiedAttrs(this.attr_copy, this.attr_orig)
      http_v1.post (
        this.apiPath(), {
          ...attr_params
        }
      ).then(response => {
        if( response.data) {
         this.$store.dispatch('cached_products/add_product', response.data.data).then(() => {
          }).catch(() => {
          })
          this.$emit('takeAction')
        }
        if(this.reroute) {
          this.$router.go(-1)
        }
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
    callCancel() {
      this.$emit('noAction')
      if(this.reroute) {
        this.$router.go(-1)
      }
    }
  },
  mounted() {
    if(this.ams_template?.attributes) {
      this.attr_copy = {...this.ams_template.attributes}
    }
    else {
      const included_obj = amsSchema.find( 
        el =>  
        el.type === this.ams_type
      )
      this.attr_copy = {...included_obj.attributes}
    }
    this.description = this.ams_template?.description || 'new brand'
  }
}
</script>